/* 
---------------------- 
Main Body Styles 
---------------------- 
*/
body {
    font-size: 125%;
    background: url(../assets/backgroundImage.png);
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    background-position: center;
}
.pageFlexContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 0;
}

h1, h2, h3, h4, h5, p, ul{
    margin: 0;
    font-weight: normal;
    text-align: center;
    font-family: $headerFont;
}

p, ul {
    font-family: $baseFont;
    list-style: none;
    padding: 0;
}

h1 {
    color: $skateOrange;
    font-size: 10rem;
    transform: rotate(-10deg);
    line-height: 8rem;
    margin: 50px 0;
    animation: glow 2s ease-in-out infinite alternate;
}
h2 {
    font-size: 3rem;
    text-transform: capitalize;
    color: $skatePink;
}

.hidden {
    display: none;
}