/* 
---------------------- 
@media Styles 
---------------------- 
*/
@media (max-width: 940px) {
    // body {
    //     border: 1px solid lime;
    // }
    form {
        width: 70%;
        button {
            width:35%;
        }
    }
}

@media (max-width: 784px) {
    // body {
    //     border: 1px solid cyan;
    // }
    .buttonContainer {
        width: 30%;
    }
    form {
        width: 80%;
        h2 {
            width: 60%;
            margin: 0 auto;
        }
        button {
            width:40%;
        }
    }
    .flexForm {
        flex-direction: column;
    }
    .setTime,
    .postalInput,
    .cityInput,
    .provinceInput {
        width: 100%;
    }
}

@media (max-width: 420px) {
    .resultsPopup {
        width: 90%;
    }
    .eachResult {
        background: $skateBlue;
        border-radius: $shape;
        padding: 5px;
        margin: 20px auto;
        width: 98%;
        button {
            width: 80%;
        }
    }
    .buttonContainer {
        width: 40%;
        margin: 10px 0;
    }
    .flexParent {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .resultsContent {
        h4 {
            font-size: 2rem;
        }
    }
    h1 {
        font-size: 6rem;
        line-height: 4rem;
        margin: 20px 0;
        animation: none;
        color: $primaryWhite;
    }
    h2 {
        font-size: 2.5rem;
        width: 70%;
    }
    form {
        input, select {
            width: 95%;
        }
        button {
            width: 80%;
        }
        h2 {
            width: 90%;
            line-height: 2.4rem;
        }
    }

}