/* 
---------------------- 
Header Styles & Circle Buttons
---------------------- 
*/
header {
  margin-top: 20px;
  p {
    text-transform: uppercase;
    letter-spacing: .4rem;
    font-weight: bold;
    color: $primaryWhite;
  }
}
.buttonContainer {
  width: 15%;
  margin: 0 15px;
}
.circle {
  border-radius: 50%;
  text-align: center;
  background: $skateBlue;
  color: $primaryWhite;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border: 3px dotted  $primaryWhite;
  -webkit-box-shadow:0px 0px 0px 5px $skateBlue;
  -moz-box-shadow:0px 0px 0px 5px $skateBlue;
  box-shadow:0px 0px 0px 5px $skateBlue;
  position: relative;
  transition: ease all 1s;
  display: flex;
  align-items: center;
  &:hover{
      transition: ease all .6s;
      border: 3px dotted $primaryWhite;
      background: $skatePink;
      color: $primaryWhite;
      -webkit-box-shadow:0px 0px 0px 5px $skatePink;
      -moz-box-shadow:0px 0px 0px 5px $skatePink;
      box-shadow:0px 0px 0px 5px $skatePink;
      animation: spin 2s linear infinite;
  }
  &:focus {
      animation: none;
  }
  button {
      border: 0;
      background: transparent;
      width: 100%;
      font-family: $headerFont;
      font-size: 3rem;
      position: absolute;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
      line-height: 2.8rem;
      text-decoration: none;
      color: $primaryWhite;
      transition: ease all 0.6s;
      padding: 100% 0;
      &:hover {
          transition: ease all 1.2s;
          color: $primaryWhite;
      }
  }
}