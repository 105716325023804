/* 
---------------------- 
Form Styles 
---------------------- 
*/
form {
    width: 50%;
    background: $transparentWhite;
    border-radius: $shape;
    height: auto;
    margin: 40px auto;
    text-align: center;
    padding: 25px;
    display: flex;
    flex-direction: column;
    button {
        border-radius: $shape;
        width: 30%;
        margin: 15px auto;
        border: 0;
        background: $skatePink;
        padding: 10px;
        text-transform: uppercase;
        font-family: $baseFont;
        color: $primaryWhite;
        font-weight: bold;
        transition: ease all .6s ;
        &:hover, :focus{
            background: $skateBlue;
        }
        
    }
    input, select {
        border-radius: 5px;
        width: 100%;
        padding: 10px;
        margin: 10px auto;
        box-shadow: inset 0 0 5px darken($skateBlue, 5%);
        border: none;
        height: 50px;
        color: lighten($skateOrange, 5%);
        
    }

}
.flexForm {
    display: flex;
    justify-content: center;
}
.setTime {
    width: 50%;
    label {
        color: $skateOrange;
    }
}

.postalInput,
.cityInput {
    width: 40%;
}
.provinceInput {
    width: 20%;
}
