/* 
---------------------- 
Main Section Styles 
---------------------- 
*/
.flexParent {
    display: flex;
    justify-content: center;
}
.thanksPopup, .resultsPopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $transparentWhite; 
    width: 50%;  
    height: auto;
    padding: 50px;  
    border-radius: $shape;
    margin: 30px auto;
    .resetForm {
        border: 0;
        border-radius: $shape;
        background: $skatePink;
        text-align: center;
        color: $primaryWhite;
        text-transform: uppercase;
        width: auto;
        padding: 20px;
        margin: 0 auto;
        transition: ease all .5s;
        &:hover {
            background: $skateBlue;
        }
    }
}
.resultsPopup {
    width: 80%;
}
.resultsContent {
    width: 100%;
    color: $primaryWhite;
    h4 {
        font-family: $headerFont;
        text-transform: capitalize;
        font-weight: bold;
        font-size: 3rem;
    }
}
.flexList {
    display: flex;
    flex-direction: column;
    text-align: center;
    li {
        margin: 15px 0;
    }
}

span.infoTitle {
    font-weight: bold;
    text-transform: uppercase;
}
.addressStyle {
    text-transform: capitalize;
}
.postalStyle {
    text-transform: uppercase;
}
.eachResult {
    background: $skateBlue;
    border-radius: $shape;
    padding: 20px;
    margin: 40px auto;
    border-bottom: 1px dotted $skatePink;
    width: 80%;
    border: 2px dotted $primaryWhite;
    box-shadow:0px 0px 0px 5px $skateBlue;
    .mailToLink {
        cursor: url('../assets/skateIcon.png'), auto;
        text-decoration: none;
        border-radius: $shape;
        display: inline-block;
        margin: 25px auto;
        background: $skatePink;
        padding: 10px;
        text-transform: uppercase;
        font-family: $baseFont;
        color: $primaryWhite;
        font-weight: bold;
        transition: ease all .6s ;
        &:hover, &:focus{
            background: $skateOrange;
        }
    }
}